import React from 'react';
import { Route, Switch } from 'react-router-dom';
import 'app/assets/index.scss';
import { Footer } from 'app/pages/Footer';
import { TermsOfService } from 'app/pages/TermsOfService';
import { PrivacyPolicy } from 'app/pages/PrivacyPolicy';
import { SignUpForm } from 'entities/Auth/components';

class App extends React.Component {
  render() {
    return (
      <>
        <Switch>
          <Route path="/" component={SignUpForm} exact />
          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
        </Switch>
        <Footer />
      </>
    );
  }
}

export default App;
