import * as React from 'react';
import axios from 'axios';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import ReactDOM from 'react-dom';
import App from './app/App';
import createStore from 'app/store/createStore';

// Axios initialization
axios.defaults.baseURL = `/api`;
axios.interceptors.request.use(config => config);
axios.interceptors.response.use(
  response => response,
  error => {
    const { status, statusText, data } = error.response;

    return Promise.reject({ data, status, statusText });
  }
);

// Render Setup

const MOUNT_NODE = document.getElementById('root');
const initialState = (window as any).___INITIAL_STATE__;
const history = createBrowserHistory();
const store = createStore(initialState, history);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  MOUNT_NODE
);
