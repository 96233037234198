import React from 'react';
import { AuthTransport } from 'entities/Auth/Auth.transport';

interface IComponentState {
  loading: boolean;
  finished: boolean;
  error?: string;
}

export class SignUpForm extends React.Component {
  public state: IComponentState = {
    loading: false,
    finished: false
  };

  public render() {
    const { loading, finished, error } = this.state;

    return (
      <div className="sign-up-container">
        <div className="sign-up">
          <div className="logo">
            <img src="/static/logo.png" alt="logo" />
          </div>
          <span className="title">Bored Kids?</span>
          <span className="info">Find local kids with similar interests with our new app! Sign up now!</span>
          <form onSubmit={this.onSubmit}>
            <label htmlFor="input-name">Name:</label>
            <input id="input-name" name="name" required disabled={finished} />
            <label htmlFor="input-email">Email</label>
            <input id="input email" type="email" name="email" required disabled={finished} />
            {finished ? <span className="result">Thank You!</span> : <button disabled={loading}>Sign Up</button>}
            {error && <span className="error">{error}</span>}
          </form>
        </div>
      </div>
    );
  }

  public onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const body = {
      name: event.target[0].value,
      email: event.target[1].value
    };

    try {
      this.setState({ loading: true, error: undefined });
      await AuthTransport.signUp(body);
      this.setState({ loading: false, finished: true });
    } catch (err) {
      if (err.status === 409) {
        this.setState({ error: 'Email is already exist' });
      } else {
        this.setState({ error: 'Error occcur' });
      }
    }
  };
}
