import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, combineReducers, compose, createStore as createReduxStore, Reducer } from 'redux';
import window from 'global/window';
import Logger from '../../common/logger';

const createStore = (initialState: any, history: History) => {
  // Middleware Configuration
  const middleware = [routerMiddleware(history)];
  const reducers = {};

  // Store Enhancers
  const windowObject = window as any;
  let composeEnhancers = compose;

  if (process.env.NODE_ENV === 'development') {
    if (typeof windowObject.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ === 'function') {
      composeEnhancers = windowObject.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
    }
  }

  try {
    // it's safe to use window now
    (reducers as any).router = connectRouter(history);
  } catch (e) {
    Logger.dbg(['CLIENT'], e);
  }

  // Store Instantiation
  const storeReducers: Reducer<any> = combineReducers({
    ...reducers
  } as any);

  const rootReducer: Reducer<any> = (state, action) => storeReducers(state, action);

  const store = createReduxStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));

  return store;
};

export default createStore;
