import React from 'react';
import { Link } from 'react-router-dom';

interface IComponentProps {
  title: string;
}

export class AgreementLayout extends React.Component<IComponentProps> {
  public render() {
    const { title, children } = this.props;

    return (
      <div className="agreement-layout-container">
        <div className="agreement-layout">
          <Link to="/">Home</Link>
          <div className="logo">
            <img src="/static/logo.png" alt="logo" />
          </div>
          <span className="title">{title}</span>
          <div className="content">{children}</div>
        </div>
      </div>
    );
  }
}
