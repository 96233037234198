import React from 'react';
import { Link } from 'react-router-dom';

export class Footer extends React.Component {
  public render() {
    return <div className="footer">
        <div className="info">
          <span>Get the App!</span>
          <div className="image-container">
            <img src="/static/app-store-icon.png" alt="Get on App Store" />
          </div>
          <div className="image-container">
            <img src="/static/google-play-icon.png" alt="Get on Play Market" />
          </div>
        </div>
        <div className="navigation">
          <Link to="/terms-of-service">Terms of Service</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>;
  }
}
